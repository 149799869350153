$sides: top, bottom, left, right;

// Generates margin/padding properties
// E.g. .margin-top-xl, .margin-bottom-xl, .margin-left-xl, .margin-right-xl
@mixin generateSpacer($property: margin, $name, $value: 0) {
  @if $name != none {
    @if $property == margin or $property == padding {
      // .margin-xl
      .#{$property}-#{$name} {
        // margin: 0;
        #{$property}: #{$value};
      }

      @each $side in $sides {
        // .margin-top-xl
        .#{$property}-#{$side}-#{$name} {
          // margin-top: 0;
          #{$property}-#{$side}: #{$value};
        }
      }
    } @else {
      @error "Invalid property type '#{$property}'. Accepted values are 'margin' or 'padding'.";
    }
  } @else {
    @error "Provide name to property.";
  }
}
