@import "../mixins/media-query-mixins";

.half-width-modal {
    --width: 50%;
}

.full-screen-modal {
    --width: 100%;
    --height: 100%;
}

.courses-modal, .plans-modal, .milestones-modal {
    @include desktop {
        --width: 60vw;
    }
}

.timetable-modal {
    --width: 100%;
    --height: 100%;

    @include desktop {
        --width: 90vw;
        --height: 90vh;
    }
}

.medium-modal {
    @media (min-width: 768px) or (min-height: 768px) {
        --width: 90%;
        --height: 90%;
    }

    @media (min-height: 800px) {
        --height: 800px;
    }

    @media (min-width: 800px) {
        --width: 800px;
    }
}

.large-modal {
    @media (min-width: 768px) or (min-height: 768px) {
        --width: 90%;
        --height: 90%;
    }

    @media (min-height: 1200px) {
        --height: 1200px;
    }

    @media (min-width: 1200px) {
        --width: 1200px;
    }
}