.label {
    font-weight: 600;
    color: var(--color-dark-1);
}

.none-found {
    font-weight: 600;
    color: var(--color-dark-1);
}

.header {
    &__notfound {
        font-weight: 600;
        font-size: var(--font-size-default);
        color: var(--color-medium-1);
        margin: var(--space-l) 0;
    }
}

.content-header  {
    h2 {
        margin: 0;
    }

    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;
    margin-bottom: var(--space-s);
}