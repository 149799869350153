@import "../mixins/fullcalendar-mixins";

// General Styles for timetable calendars
#timetableWeekCalendar, #timetableMonthCalendar {
    .fc-toolbar {
        justify-content: center;
    }

    .fc-toolbar-title {
        font-size: var(--font-size-l);
    }

    .fc-button {
        font-size: var(--font-size-s);
    }

    .fc-toolbar-chunk:nth-child(2) {
        margin: 0 50px;
        color: var(--color-primary);
    }

    .fc-col-header-cell a {
        color: var(--color-dark-1);
    }

    .fc-event {
        cursor: pointer;
        z-index: 999;
    }
}

// Timetable main calender styles
#timetableWeekCalendar {
    .fc-button-primary {
        background-color: var(--color-primary);
        border-color: var(--color-primary);
        color: var(--color-light);

        &:hover {
            background-color: var(--color-primary);
            border-color: var(--color-primary);
            opacity: .9;
        }
    }

    @include generateColorSwatches('event');
    @include generateColorSwatches('enrolment');
}

// Timetable mini calendar styles
#timetableMonthCalendar {
    .fc-daygrid-day-number {
        color: var(--color-dark-1);
    }

    .fc-daygrid-day {
        border: none;
    }

    .fc-daygrid-day-top {
        justify-content: center;
    }

    .fc-theme-standard th {
        border: none;

        &:last-child {
            border-right: 1px solid var(--fc-border-color);
        }
    }

    .fc-col-header thead {
        border-bottom: 1px solid var(--fc-border-color);
    }

    .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
        min-height: 30px;
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 999;
    }

    .fc-event {
        height: 30px;
        background-color: var(--color-secondary);
        opacity: 0.34;
    }

    .fc-daygrid-day-bottom {
        display: none;
    }

    .fc-button-primary {
        background-color: transparent;
        border-color: transparent;
        color: var(--color-primary);

        &:hover {
            background-color: transparent;
            border-color: transparent;
        }
    }
}