@use "sass:map";

// Calendar swatches color
$calendarColors: (
        booking-confirmed: #2EC95C,
        bookiing-requested:#FDD835,
        lecture: #0731F7,
        tutorial: #DC2700,
        laboratory: #FF33A1,
        workshop: #FFC733,
        seminar: #33FFF6,
        drop-in-clinic: #B663FD,
        field-trip: #FB8702,
        studio: #71DF9F,
        team-based-learning: #6235C0,
        clinic: #FF0F6A,
        overflow: #33C7FF,
        practicum: #E7A6FF,
        independent-study: #FE6E50,
        setup: #9BF46B,
        online-course: #3392FF,
        thesis: #FF33D1,
        plenary: #00877C,
        dropped: var(--color-error),
        enrolled: var(--color-success),
        waiting: var(--color-secondary),
        unenrolled: #3788d8,
        default: #3788d8
);

@mixin generateColorSwatches($prefix) {
  @each $name, $color in $calendarColors {
    .#{$prefix}-#{$name} {
      background-color: map.get($calendarColors, $name);
      border-color: map.get($calendarColors, $name);
    }
  }
}