:root {
  --color-medium-1: #979797;
  --color-medium-2: #D4D4D4;
  --color-background-medium: rgb(242,242,242);
  --color-warning-2: #FDD835;
  --color-warning-2-rgb: 253, 216, 53;
}

:root {
  /*
  * Add overrides to framework classes here
  */
}
:root .table ion-button {
  --border-radius: 8px;
}
:root .table ion-row {
  border: solid 1px var(--color-medium-1);
}
:root .table ion-col {
  padding: var(--space-l);
}
:root .table ion-col:not(:last-child) {
  border-right: solid 1px var(--color-medium-1);
}
:root .table__thead {
  background-color: rgb(var(--color-secondary-rgb), 0.28);
}
:root .table__thead ion-col {
  color: var(--color-primary);
  font-weight: bold;
  text-align: left;
  align-content: center;
}
:root .table__tbody {
  color: var(--color-primary);
}
:root .table__tbody > ion-row {
  border-top: none;
}
:root .table__tbody > ion-row:nth-child(even) {
  background-color: var(--color-light-1);
}
:root .label {
  font-weight: 600;
  color: var(--color-dark-1);
}
:root .none-found {
  font-weight: 600;
  color: var(--color-dark-1);
}
:root .header__notfound {
  font-weight: 600;
  font-size: var(--font-size-default);
  color: var(--color-medium-1);
  margin: var(--space-l) 0;
}
:root .content-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
  margin-bottom: var(--space-s);
}
:root .content-header h2 {
  margin: 0;
}
:root .half-width-modal {
  --width: 50%;
}
:root .full-screen-modal {
  --width: 100%;
  --height: 100%;
}
@media (min-width: 992px) {
  :root .courses-modal, :root .plans-modal, :root .milestones-modal {
    --width: 60vw;
  }
}
:root .timetable-modal {
  --width: 100%;
  --height: 100%;
}
@media (min-width: 992px) {
  :root .timetable-modal {
    --width: 90vw;
    --height: 90vh;
  }
}
@media (min-width: 768px) or (min-height: 768px) {
  :root .medium-modal {
    --width: 90%;
    --height: 90%;
  }
}
@media (min-height: 800px) {
  :root .medium-modal {
    --height: 800px;
  }
}
@media (min-width: 800px) {
  :root .medium-modal {
    --width: 800px;
  }
}
@media (min-width: 768px) or (min-height: 768px) {
  :root .large-modal {
    --width: 90%;
    --height: 90%;
  }
}
@media (min-height: 1200px) {
  :root .large-modal {
    --height: 1200px;
  }
}
@media (min-width: 1200px) {
  :root .large-modal {
    --width: 1200px;
  }
}
:root .inner-content {
  max-width: unset;
}
:root .searchbar-search-icon.sc-ion-searchbar-md {
  display: none;
  width: 0;
  height: 0;
}
:root h2 {
  margin-top: 40px;
}
:root .inner-content {
  max-width: unset;
  padding-right: 20px;
  padding-left: 20px;
}
@media (min-width: 992px) {
  :root .inner-content {
    padding-right: var(--space-4xl);
    padding-left: var(--space-4xl);
  }
}
:root #timetableWeekCalendar .fc-toolbar, :root #timetableMonthCalendar .fc-toolbar {
  justify-content: center;
}
:root #timetableWeekCalendar .fc-toolbar-title, :root #timetableMonthCalendar .fc-toolbar-title {
  font-size: var(--font-size-l);
}
:root #timetableWeekCalendar .fc-button, :root #timetableMonthCalendar .fc-button {
  font-size: var(--font-size-s);
}
:root #timetableWeekCalendar .fc-toolbar-chunk:nth-child(2), :root #timetableMonthCalendar .fc-toolbar-chunk:nth-child(2) {
  margin: 0 50px;
  color: var(--color-primary);
}
:root #timetableWeekCalendar .fc-col-header-cell a, :root #timetableMonthCalendar .fc-col-header-cell a {
  color: var(--color-dark-1);
}
:root #timetableWeekCalendar .fc-event, :root #timetableMonthCalendar .fc-event {
  cursor: pointer;
  z-index: 999;
}
:root #timetableWeekCalendar .fc-button-primary {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
  color: var(--color-light);
}
:root #timetableWeekCalendar .fc-button-primary:hover {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
  opacity: 0.9;
}
:root #timetableWeekCalendar .event-booking-confirmed {
  background-color: #2EC95C;
  border-color: #2EC95C;
}
:root #timetableWeekCalendar .event-bookiing-requested {
  background-color: #FDD835;
  border-color: #FDD835;
}
:root #timetableWeekCalendar .event-lecture {
  background-color: #0731F7;
  border-color: #0731F7;
}
:root #timetableWeekCalendar .event-tutorial {
  background-color: #DC2700;
  border-color: #DC2700;
}
:root #timetableWeekCalendar .event-laboratory {
  background-color: #FF33A1;
  border-color: #FF33A1;
}
:root #timetableWeekCalendar .event-workshop {
  background-color: #FFC733;
  border-color: #FFC733;
}
:root #timetableWeekCalendar .event-seminar {
  background-color: #33FFF6;
  border-color: #33FFF6;
}
:root #timetableWeekCalendar .event-drop-in-clinic {
  background-color: #B663FD;
  border-color: #B663FD;
}
:root #timetableWeekCalendar .event-field-trip {
  background-color: #FB8702;
  border-color: #FB8702;
}
:root #timetableWeekCalendar .event-studio {
  background-color: #71DF9F;
  border-color: #71DF9F;
}
:root #timetableWeekCalendar .event-team-based-learning {
  background-color: #6235C0;
  border-color: #6235C0;
}
:root #timetableWeekCalendar .event-clinic {
  background-color: #FF0F6A;
  border-color: #FF0F6A;
}
:root #timetableWeekCalendar .event-overflow {
  background-color: #33C7FF;
  border-color: #33C7FF;
}
:root #timetableWeekCalendar .event-practicum {
  background-color: #E7A6FF;
  border-color: #E7A6FF;
}
:root #timetableWeekCalendar .event-independent-study {
  background-color: #FE6E50;
  border-color: #FE6E50;
}
:root #timetableWeekCalendar .event-setup {
  background-color: #9BF46B;
  border-color: #9BF46B;
}
:root #timetableWeekCalendar .event-online-course {
  background-color: #3392FF;
  border-color: #3392FF;
}
:root #timetableWeekCalendar .event-thesis {
  background-color: #FF33D1;
  border-color: #FF33D1;
}
:root #timetableWeekCalendar .event-plenary {
  background-color: #00877C;
  border-color: #00877C;
}
:root #timetableWeekCalendar .event-dropped {
  background-color: var(--color-error);
  border-color: var(--color-error);
}
:root #timetableWeekCalendar .event-enrolled {
  background-color: var(--color-success);
  border-color: var(--color-success);
}
:root #timetableWeekCalendar .event-waiting {
  background-color: var(--color-secondary);
  border-color: var(--color-secondary);
}
:root #timetableWeekCalendar .event-unenrolled {
  background-color: #3788d8;
  border-color: #3788d8;
}
:root #timetableWeekCalendar .event-default {
  background-color: #3788d8;
  border-color: #3788d8;
}
:root #timetableWeekCalendar .enrolment-booking-confirmed {
  background-color: #2EC95C;
  border-color: #2EC95C;
}
:root #timetableWeekCalendar .enrolment-bookiing-requested {
  background-color: #FDD835;
  border-color: #FDD835;
}
:root #timetableWeekCalendar .enrolment-lecture {
  background-color: #0731F7;
  border-color: #0731F7;
}
:root #timetableWeekCalendar .enrolment-tutorial {
  background-color: #DC2700;
  border-color: #DC2700;
}
:root #timetableWeekCalendar .enrolment-laboratory {
  background-color: #FF33A1;
  border-color: #FF33A1;
}
:root #timetableWeekCalendar .enrolment-workshop {
  background-color: #FFC733;
  border-color: #FFC733;
}
:root #timetableWeekCalendar .enrolment-seminar {
  background-color: #33FFF6;
  border-color: #33FFF6;
}
:root #timetableWeekCalendar .enrolment-drop-in-clinic {
  background-color: #B663FD;
  border-color: #B663FD;
}
:root #timetableWeekCalendar .enrolment-field-trip {
  background-color: #FB8702;
  border-color: #FB8702;
}
:root #timetableWeekCalendar .enrolment-studio {
  background-color: #71DF9F;
  border-color: #71DF9F;
}
:root #timetableWeekCalendar .enrolment-team-based-learning {
  background-color: #6235C0;
  border-color: #6235C0;
}
:root #timetableWeekCalendar .enrolment-clinic {
  background-color: #FF0F6A;
  border-color: #FF0F6A;
}
:root #timetableWeekCalendar .enrolment-overflow {
  background-color: #33C7FF;
  border-color: #33C7FF;
}
:root #timetableWeekCalendar .enrolment-practicum {
  background-color: #E7A6FF;
  border-color: #E7A6FF;
}
:root #timetableWeekCalendar .enrolment-independent-study {
  background-color: #FE6E50;
  border-color: #FE6E50;
}
:root #timetableWeekCalendar .enrolment-setup {
  background-color: #9BF46B;
  border-color: #9BF46B;
}
:root #timetableWeekCalendar .enrolment-online-course {
  background-color: #3392FF;
  border-color: #3392FF;
}
:root #timetableWeekCalendar .enrolment-thesis {
  background-color: #FF33D1;
  border-color: #FF33D1;
}
:root #timetableWeekCalendar .enrolment-plenary {
  background-color: #00877C;
  border-color: #00877C;
}
:root #timetableWeekCalendar .enrolment-dropped {
  background-color: var(--color-error);
  border-color: var(--color-error);
}
:root #timetableWeekCalendar .enrolment-enrolled {
  background-color: var(--color-success);
  border-color: var(--color-success);
}
:root #timetableWeekCalendar .enrolment-waiting {
  background-color: var(--color-secondary);
  border-color: var(--color-secondary);
}
:root #timetableWeekCalendar .enrolment-unenrolled {
  background-color: #3788d8;
  border-color: #3788d8;
}
:root #timetableWeekCalendar .enrolment-default {
  background-color: #3788d8;
  border-color: #3788d8;
}
:root #timetableMonthCalendar .fc-daygrid-day-number {
  color: var(--color-dark-1);
}
:root #timetableMonthCalendar .fc-daygrid-day {
  border: none;
}
:root #timetableMonthCalendar .fc-daygrid-day-top {
  justify-content: center;
}
:root #timetableMonthCalendar .fc-theme-standard th {
  border: none;
}
:root #timetableMonthCalendar .fc-theme-standard th:last-child {
  border-right: 1px solid var(--fc-border-color);
}
:root #timetableMonthCalendar .fc-col-header thead {
  border-bottom: 1px solid var(--fc-border-color);
}
:root #timetableMonthCalendar .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
  min-height: 30px;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 999;
}
:root #timetableMonthCalendar .fc-event {
  height: 30px;
  background-color: var(--color-secondary);
  opacity: 0.34;
}
:root #timetableMonthCalendar .fc-daygrid-day-bottom {
  display: none;
}
:root #timetableMonthCalendar .fc-button-primary {
  background-color: transparent;
  border-color: transparent;
  color: var(--color-primary);
}
:root #timetableMonthCalendar .fc-button-primary:hover {
  background-color: transparent;
  border-color: transparent;
}
:root .margin-xs {
  margin: var(--space-xs);
}
:root .margin-top-xs {
  margin-top: var(--space-xs);
}
:root .margin-bottom-xs {
  margin-bottom: var(--space-xs);
}
:root .margin-left-xs {
  margin-left: var(--space-xs);
}
:root .margin-right-xs {
  margin-right: var(--space-xs);
}
:root .margin-sm {
  margin: var(--space-sm);
}
:root .margin-top-sm {
  margin-top: var(--space-sm);
}
:root .margin-bottom-sm {
  margin-bottom: var(--space-sm);
}
:root .margin-left-sm {
  margin-left: var(--space-sm);
}
:root .margin-right-sm {
  margin-right: var(--space-sm);
}
:root .margin-md {
  margin: var(--space-md);
}
:root .margin-top-md {
  margin-top: var(--space-md);
}
:root .margin-bottom-md {
  margin-bottom: var(--space-md);
}
:root .margin-left-md {
  margin-left: var(--space-md);
}
:root .margin-right-md {
  margin-right: var(--space-md);
}
:root .margin-lg {
  margin: var(--space-lg);
}
:root .margin-top-lg {
  margin-top: var(--space-lg);
}
:root .margin-bottom-lg {
  margin-bottom: var(--space-lg);
}
:root .margin-left-lg {
  margin-left: var(--space-lg);
}
:root .margin-right-lg {
  margin-right: var(--space-lg);
}
:root .margin-xl {
  margin: var(--space-xl);
}
:root .margin-top-xl {
  margin-top: var(--space-xl);
}
:root .margin-bottom-xl {
  margin-bottom: var(--space-xl);
}
:root .margin-left-xl {
  margin-left: var(--space-xl);
}
:root .margin-right-xl {
  margin-right: var(--space-xl);
}
:root .margin-2xl {
  margin: var(--space-2xl);
}
:root .margin-top-2xl {
  margin-top: var(--space-2xl);
}
:root .margin-bottom-2xl {
  margin-bottom: var(--space-2xl);
}
:root .margin-left-2xl {
  margin-left: var(--space-2xl);
}
:root .margin-right-2xl {
  margin-right: var(--space-2xl);
}
:root .margin-3xl {
  margin: var(--space-3xl);
}
:root .margin-top-3xl {
  margin-top: var(--space-3xl);
}
:root .margin-bottom-3xl {
  margin-bottom: var(--space-3xl);
}
:root .margin-left-3xl {
  margin-left: var(--space-3xl);
}
:root .margin-right-3xl {
  margin-right: var(--space-3xl);
}
:root .margin-4xl {
  margin: var(--space-4xl);
}
:root .margin-top-4xl {
  margin-top: var(--space-4xl);
}
:root .margin-bottom-4xl {
  margin-bottom: var(--space-4xl);
}
:root .margin-left-4xl {
  margin-left: var(--space-4xl);
}
:root .margin-right-4xl {
  margin-right: var(--space-4xl);
}
:root .margin-5xl {
  margin: var(--space-5xl);
}
:root .margin-top-5xl {
  margin-top: var(--space-5xl);
}
:root .margin-bottom-5xl {
  margin-bottom: var(--space-5xl);
}
:root .margin-left-5xl {
  margin-left: var(--space-5xl);
}
:root .margin-right-5xl {
  margin-right: var(--space-5xl);
}
:root .padding-xs {
  padding: var(--space-xs);
}
:root .padding-top-xs {
  padding-top: var(--space-xs);
}
:root .padding-bottom-xs {
  padding-bottom: var(--space-xs);
}
:root .padding-left-xs {
  padding-left: var(--space-xs);
}
:root .padding-right-xs {
  padding-right: var(--space-xs);
}
:root .padding-sm {
  padding: var(--space-sm);
}
:root .padding-top-sm {
  padding-top: var(--space-sm);
}
:root .padding-bottom-sm {
  padding-bottom: var(--space-sm);
}
:root .padding-left-sm {
  padding-left: var(--space-sm);
}
:root .padding-right-sm {
  padding-right: var(--space-sm);
}
:root .padding-md {
  padding: var(--space-md);
}
:root .padding-top-md {
  padding-top: var(--space-md);
}
:root .padding-bottom-md {
  padding-bottom: var(--space-md);
}
:root .padding-left-md {
  padding-left: var(--space-md);
}
:root .padding-right-md {
  padding-right: var(--space-md);
}
:root .padding-lg {
  padding: var(--space-lg);
}
:root .padding-top-lg {
  padding-top: var(--space-lg);
}
:root .padding-bottom-lg {
  padding-bottom: var(--space-lg);
}
:root .padding-left-lg {
  padding-left: var(--space-lg);
}
:root .padding-right-lg {
  padding-right: var(--space-lg);
}
:root .padding-xl {
  padding: var(--space-xl);
}
:root .padding-top-xl {
  padding-top: var(--space-xl);
}
:root .padding-bottom-xl {
  padding-bottom: var(--space-xl);
}
:root .padding-left-xl {
  padding-left: var(--space-xl);
}
:root .padding-right-xl {
  padding-right: var(--space-xl);
}
:root .padding-2xl {
  padding: var(--space-2xl);
}
:root .padding-top-2xl {
  padding-top: var(--space-2xl);
}
:root .padding-bottom-2xl {
  padding-bottom: var(--space-2xl);
}
:root .padding-left-2xl {
  padding-left: var(--space-2xl);
}
:root .padding-right-2xl {
  padding-right: var(--space-2xl);
}
:root .padding-3xl {
  padding: var(--space-3xl);
}
:root .padding-top-3xl {
  padding-top: var(--space-3xl);
}
:root .padding-bottom-3xl {
  padding-bottom: var(--space-3xl);
}
:root .padding-left-3xl {
  padding-left: var(--space-3xl);
}
:root .padding-right-3xl {
  padding-right: var(--space-3xl);
}
:root .padding-4xl {
  padding: var(--space-4xl);
}
:root .padding-top-4xl {
  padding-top: var(--space-4xl);
}
:root .padding-bottom-4xl {
  padding-bottom: var(--space-4xl);
}
:root .padding-left-4xl {
  padding-left: var(--space-4xl);
}
:root .padding-right-4xl {
  padding-right: var(--space-4xl);
}
:root .padding-5xl {
  padding: var(--space-5xl);
}
:root .padding-top-5xl {
  padding-top: var(--space-5xl);
}
:root .padding-bottom-5xl {
  padding-bottom: var(--space-5xl);
}
:root .padding-left-5xl {
  padding-left: var(--space-5xl);
}
:root .padding-right-5xl {
  padding-right: var(--space-5xl);
}
:root .margin-0 {
  margin: 0;
}
:root .margin-top-0 {
  margin-top: 0;
}
:root .margin-bottom-0 {
  margin-bottom: 0;
}
:root .margin-left-0 {
  margin-left: 0;
}
:root .margin-right-0 {
  margin-right: 0;
}
:root .padding-0 {
  padding: 0;
}
:root .padding-top-0 {
  padding-top: 0;
}
:root .padding-bottom-0 {
  padding-bottom: 0;
}
:root .padding-left-0 {
  padding-left: 0;
}
:root .padding-right-0 {
  padding-right: 0;
}