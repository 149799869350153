@import "media-mixins";

/*
* Add overrides to framework classes here
*/

.inner-content {
    max-width: unset;
}

// Removes search icon
.searchbar-search-icon.sc-ion-searchbar-md {
    display:none;
    width:0;
    height:0;
}

h2 {
    margin-top: 40px;
}

.inner-content {
    max-width: unset;
    padding-right: 20px;
    padding-left: 20px;

    @include desktop {
        padding-right:  var(--space-4xl);
        padding-left:  var(--space-4xl);
    }
}