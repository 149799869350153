.table {
    // General styles
    ion-button {
        --border-radius: 8px;
    }

    ion-row {
        border: solid 1px var(--color-medium-1);
    }

    ion-col {
        padding: var(--space-l);

        &:not(:last-child) {
            border-right: solid 1px var(--color-medium-1);
        }
    }

    // Table head styles
    &__thead {
        background-color: rgb(var(--color-secondary-rgb), .28);

        & ion-col {
            color: var(--color-primary);
            font-weight: bold;
            text-align: left;
            align-content: center;
        }
    }

    // Table body styles
    &__tbody {
        color: var(--color-primary);

        & > ion-row {
            border-top: none;

            &:nth-child(even) {
                background-color: var(--color-light-1);
            }
        }
    }
}